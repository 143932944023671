<template>
  <div>
    <div v-if="hasOrder">
      <b-link @click="newOrder()" class="ml-2">
        <b-button>
          <span>
            {{ $t('orders.new_order') }}
          </span>
        </b-button>
      </b-link>
    </div>
    <div v-else>
      <b-link :to="{ name: 'steps' }" size="sm">
        <b-button>
          <span>
            {{ $t('orders.new_order') }}
          </span>
        </b-button>
      </b-link>
    </div>

</div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import {
  BButton, BLink,
} from 'bootstrap-vue'
import { TimestampToFlatPickr, TimestampToFlatPickrOnlyYear } from '@/libs/helpers'

export default {
  components: {
    BButton,
    BLink,
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters({
      order: 'steps/getOrder',
    }),
    hasOrder() {
      return Object.keys(this.order).length
    },
  },
  methods: {
    ...mapMutations({
      recoverCurrentOrder: 'steps/recoverCurrentOrder',
      setStep: 'steps/setStep',
    }),
    newOrder() {
      localStorage.removeItem('order')
      location.href = '/rater/steps'
      // this.$router.push({ name: 'steps' })
    },
    dateColumnYear(time) {
      return TimestampToFlatPickrOnlyYear(time)
    },
    async setStepOrder() {
      if (this.hasOrder) {
        this.setStep(4)
      }
    },
  },
  async created() {
    await this.recoverCurrentOrder()
    await this.setStepOrder()
  },
}
</script>
